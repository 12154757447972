import { APP_NAME } from 'constants/app';
import { cn } from 'utils/commonUtils';

export default function Logo({
  className,
  isDark = false,
  name = APP_NAME,
  isMobile = false,
}) {
  return (
    <div className={cn('flex', className)}>
      <div
        className={cn('h-10 inline-flex', {
          'max-[991px]:brightness-0 max-[991px]:invert': isMobile,
        })}
      >
        <img
          src='/images/logo.png'
          alt=''
          className='max-h-full w-auto inline-block'
        />
      </div>

      {name ? (
        <span
          className={cn(
            'text-sm inline-block font-semibold',
            isDark ? 'text-black' : 'text-white'
          )}
        >
          {name}
        </span>
      ) : null}
    </div>
  );
}
