export const ROUTES = {
  ROOT: '/',

  LOGIN: 'login',

  DASHBOARD: 'dashboard',

  PROVIDERS: 'providers',
  PROVIDER_RULES: 'provider-rules',

  ROLES: 'roles',

  USER_PERMISSIONS: 'user-permissions',
  USER_POLICIES: 'user-policies',

  USERS: 'users',
  SUB_USERS: 'sub-users',
  PERMISSIONS: 'permissions',

  GROUPS: 'groups',
  GROUP_DETAIL: 'group-detail',

  ACCOUNTS: 'accounts',
  SUB_ACCOUNTS: 'sub-accounts',

  SYSTEM_POLICIES: 'system-policies',
  SUBADMIN_PERMISSIONS: 'subadmin-permissions',
  SUBADMIN_POLICIES: 'subadmin-policies',

  DEFAULT_DNS: 'default-dns',

  ORDERS: 'orders',

  PROMO_CODES: 'promo-codes',

  INVOICES: 'invoices',

  SERVER_INFO: 'server-info',

  STORAGE_ACCOUNTS: 'storage-accounts',

  LIVE_STREAMING: 'live-streaming',

  // Infrastructure
  OVERVIEW: 'overview',
  EVENTS: 'events',
  SERVICES: 'services',
  DEVICES: 'devices',
  ROUTING: 'routing',

  LOGS: 'logs',

  DATA_USAGE: 'data-usage',

  DNS_TEMPLATES: 'dns-templates',
  DNS_GROUPS: 'dns-groups',
  DNS_ZONES: 'dns-zones',

  TRANSACTIONS: 'transactions',
  ITEM_PRICE: 'item-price',

  ALARMS: 'alarms',
};

export const ROUTE_ACTIONS = {
  ADD: 'add',
  DETAILS: 'id',
  SUB: 'childId',
};
