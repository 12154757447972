import { Suspense, useState } from 'react';
import { Button, Layout, Grid, Drawer } from 'antd';
import { Outlet } from 'react-router-dom';
import { PanelLeftClose, PanelLeftOpen } from 'lucide-react';

import { Loading, Logo } from 'components/ui';
import { DashboardHeader } from './components/DashboardHeader';
import { DashboardMenu } from './components/DashboardMenu';
// import { Footer } from './components/Footer';

export const DashboardLayout = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const screens = Grid.useBreakpoint();

  const handleToggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  return (
    <>
      <Layout hasSider style={{ minHeight: '100vh' }}>
        {screens.lg && (
          <Layout.Sider
            collapsible
            collapsed={isCollapsed}
            trigger={null}
            className='!overflow-auto !h-screen !fixed !left-0 !top-0 !bottom-0 pt-12'
            width={256}
          >
            <div className='h-full flex flex-col'>
              <div className='flex-1 overflow-y-auto'>
                <DashboardMenu />
              </div>

              <Button type='primary' onClick={handleToggleCollapse}>
                {isCollapsed ? <PanelLeftOpen /> : <PanelLeftClose />}
              </Button>
            </div>
          </Layout.Sider>
        )}

        <Layout
          style={{
            marginLeft: screens.lg ? (isCollapsed ? 80 : 256) : 0,
            transition: 'all 0.2s ease',
          }}
        >
          <DashboardHeader onCollapse={handleToggleCollapse} />

          <Layout.Content className='md:!p-6 !p-4 !flex-1'>
            <Suspense fallback={<Loading />}>
              <Outlet />
            </Suspense>
          </Layout.Content>

          {/* <Footer /> */}
        </Layout>
      </Layout>

      {!screens.lg && (
        <Drawer
          open={isCollapsed}
          width={256}
          placement='left'
          closeIcon={null}
          onClose={handleToggleCollapse}
          styles={{
            body: {
              padding: 0,
            },
          }}
        >
          <Logo
            className='flex-col items-center py-2 !bg-primary space-y-2'
            isMobile
          />

          <Layout.Sider trigger={null} width={256}>
            <DashboardMenu theme='light' />
          </Layout.Sider>
        </Drawer>
      )}
    </>
  );
};
